.imprintHeader {
  text-align: center;
  font-size: 18px;
  margin: 0px 30px 4px 30px;
  text-transform: uppercase;
  font-weight: bold;
}

.imprintParagraph {
  text-align: center;
  font-size: 16px;
  color: gray;
  line-height: 1.5;
  text-transform: uppercase;
}
