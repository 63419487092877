.portfolio-image {
  vertical-align: middle;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.portfolio-image-wrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity 200ms linear;
  overflow: hidden;
  line-height: 0;
}

@media (max-width: 1000px) {
  .portfolio-image-wrapper {
    height: 70vh;
  }
}

@media (max-width: 700px) {
  .portfolio-image-wrapper {
    height: 50vh;
  }
}

.mobileHeaderSpace {
  width: 100%;
  height: 95px;
  background-color: white;
}
