.image-lazy-load-placeholder-skeletion {
  width: 100%;
  height: 40vh;
  cursor: progress;
  background: linear-gradient(0.25turn, transparent, #fff, transparent),
    linear-gradient(#eee, #eee);

  background-repeat: no-repeat;

  background-position: -100vw 0, 0 0;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  to {
    background-position: 100vw 0, 0 0;
  }
}
