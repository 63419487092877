.footer-basic {
  padding: 40px 0;
  background-color: #f9f9f9;
  color: #4b4c4d;
  border-top: 1px solid #ebebeb;
}

.list-inline {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  font-size: 18px;
  line-height: 1.6;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.footer-basic ul {
  padding: 0;
  list-style: none;
  text-align: center;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 0;
}

.footer-basic li {
  padding: 0 10px;
}

.footer-basic ul a {
  color: inherit;
  text-decoration: none;
  opacity: 0.8;
}

.footer-basic ul a:hover {
  opacity: 1;
}

.footer-basic .social {
  text-align: center;
  padding-bottom: 25px;
}

.footer-basic .social > a {
  font-size: 24px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
  color: #4b4c4d;
}

.footer-basic .social > a:hover {
  opacity: 0.9;
}

.footer-basic .copyright {
  margin-top: 15px;
  text-align: center;
  font-size: 13px;
  color: #aaa;
  margin-bottom: 0;
}
