.Container {
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
}

@media screen and (max-width: 991px) {
  .Container {
    padding-right: 30px;
    padding-left: 30px;
  }
}

.WideContainer {
  z-index: 1;
  width: 100%;
  max-width: 2000px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
  @media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
  }
}

.marginBottom {
  margin-bottom: 50px;
}

.roboto-font {
  font-family: "Roboto", "Source Sans Pro", sans-serif;
}

.flex-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.no-textdeco {
  text-decoration: none;
}

.level0 {
  color: rgba(74, 74, 76, 1);
}

.grayColor {
  color: gray;
}

.full-sceen-image-desc {
  color: white;
  position: absolute;
  font-size: 50px;
  margin: 0px 40px 0px 40px;
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .full-sceen-image-desc {
    font-size: 11px;
  }
}

@media screen and (max-width: 972px) {
  .full-sceen-image-desc {
    font-size: 40px;
    text-align: center;
  }
}

@media screen and (max-width: 672px) {
  .full-sceen-image-desc {
    font-size: 30px;
    text-align: center;
  }
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

body::-webkit-scrollbar-thumb {
  background: #aaaaaa;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb:hover {
  background: #606060;
}

.opacityFadeEffect {
  animation: fadein 1s;
  -moz-animation: fadein 1s; /* Firefox */
  -webkit-animation: fadein 1s; /* Safari and Chrome */
  -o-animation: fadein 1s; /* Opera */
}
.fontAppearEffect {
  animation: fadein 3s linear;
  -moz-animation: fadein 3s; /* Firefox */
  -webkit-animation: fadein 3s; /* Safari and Chrome */
  -o-animation: fadein 3s; /* Opera */
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.imageZoomInEffect {
  animation: zoomIn 2s;
  -moz-animation: zoomIn 2s; /* Firefox */
  -webkit-animation: zoomIn 2s; /* Safari and Chrome */
  -o-animation: zoomIn 2s; /* Opera */
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
}

@keyframes zoomIn {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
  }
}
@-moz-keyframes zoomIn {
  /* Firefox */
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
  }
}
@-webkit-keyframes zoomIn {
  /* Safari and Chrome */
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
  }
}
@-o-keyframes zoomIn {
  /* Opera */
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
  }
}

.simple-header-area {
  width: 100%;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 30px;
}

.simpleHeader {
  padding-right: 50px;
  padding-left: 50px;
  font-size: 36px;
  line-height: 1.1;
  font-weight: 600;
  color: rgba(74, 74, 76, 1);
}
