.praefix {
  float: left;
  font-weight: bold;
  width: 100%;
  margin-bottom: 10px;
  color: rgba(74, 74, 76, 1);
}

.contact-container {
  width: fit-content;
  margin: auto;
  padding-bottom: 35px;
}

.contact-wrapper {
  width: 100%;
  display: flex;
  margin-top: 90px;
  margin-bottom: 55px;
  flex-wrap: wrap;
  align-items: center;
}

.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-bottom: 15px;
}

.icons {
  text-align: center;
}

.desc {
  color: gray;
  margin-left: 20px;
  margin-right: 20px;
}
