.image-gallery-filter-area {
  width: 100%;
  padding-top: 90px;
  padding-bottom: 60px;
  background-color: white;
}

.image-gallery-filter-header {
  font-size: 36px;
  line-height: 1.1;
  font-weight: 600;
  color: rgba(74, 74, 76, 1);
  /*background-color: blue;*/
  width: fit-content;
}

.image-gallery-actual-filter {
  /*background-color: red;*/
  margin-left: auto;
  margin-top: auto;
}

.ui-list-style-none {
  list-style: none;
  display: flex;
}

.filter-list-inline-item {
  margin: 0 10px;
  padding-bottom: 7px;
  border-bottom: 1px solid transparent;
}

.filter-list-inline-item:hover {
  border-bottom: 1px solid gray;
  cursor: pointer;
}

.filter-list-inline-item-active {
  color: black;
}

@media (max-width: 700px) {
  .image-gallery-actual-filter {
    width: 100%;
    margin-top: 25px;
    /*background-color: black;*/
  }
  .ui-center {
    justify-content: center;
  }

  .image-gallery-filter-header {
    width: 100%;
    text-align: center;
  }
}
