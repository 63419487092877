.mySlides {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: none;
}
.slideImage {
  vertical-align: middle;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Slideshow container */
.slideshow-container {
  width: 100%;
  height: 100vh;
  position: relative;
  margin: auto;
  overflow: hidden;
  line-height: 0;
}

@media (max-width: 1000px) {
  .slideshow-container {
    height: 70vh;
  }
}

@media (max-width: 700px) {
  .slideshow-container {
    height: 50vh;
  }
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  width: auto;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  border-radius: 10px;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.prev {
  left: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(74, 74, 76, 1);
  border-radius: 10px;
}

/* Caption text */
.welcomeText {
  color: white;
  position: absolute;
  font-size: 60px;
  margin: 0px 40px 0px 40px;
  font-weight: 100;
  letter-spacing: 5px;
  bottom: 140px;

}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .prev,
  .next,
  .welcomeText {
    font-size: 11px;
  }
}

@media screen and (max-width: 972px) {
  .welcomeText {
    font-size: 40px;
    text-align: center;
  }
}

@media screen and (max-width: 672px) {
  .welcomeText {
    font-size: 30px;
    text-align: center;
  }
}

.inactiveLink {
  pointer-events: none;
  cursor: default;
}

.mobileHeaderSpaceHome {
  width: 100%;
  height: 60px;
  background-color: white;
}
