.map {
  height: 40vh;
}
.leaflet-bottom .leaflet-right {
  display: none;
}

.mapImg{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mapImgWrapper{
  width: 100%;
  height: 33vh;
}