.portfolio-navbar-container {
  background-color: transparent;
  position: fixed;
  top: 0;
  width: 100%;
  text-align: center;
  z-index: 999;
}

.portfolio-navbar-name {
  color: black;
}

.portfolio-navbar-toggle-top {
  height: 32px;
  transition: height 4s;
}

.portfolio-navbar-top-placeholder {
  height: 10px;
  width: 100%;
}

.portfolio-navbar-divider {
  display: block;
  height: 2px;
  border: 0;
  border-top: 2px solid black;
  border-radius: 50px;
  width: 40px;
  margin-left: auto;
  margin-right: auto;
}

.portfolio-navbar-top {
  display: block;
  height: 2px;
  border: 0;
  border-top: 2px solid black;
  border-radius: 50px;
  width: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4px;
  margin-bottom: 4px;
}

.portfolio-navbar-list-inline {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  font-size: 16px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  padding: 0;
  list-style: none;
  line-height: 1.6;
}

.portfolio-navbar-list-inline-item {
  margin: 0 10px;
  padding-bottom: 7px;
  border-bottom: 1px solid transparent;
}

.portfolio-navbar-list-inline-item:hover {
  border-bottom: 1px solid black;
}

.portfolio-navbar-list-inline-item a {
  color: black;
  text-decoration: none;
}

.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 200ms linear, visibility 200ms linear,
    height 200ms ease-in-out;
  transition-delay: 100ms;
}

.hidden {
  height: 0;
  visibility: hidden;
  opacity: 0;
  transition: opacity 100ms linear, visibility 100ms linear, height 100ms linear;
}

.whiteBG {
  background-color: white;
  transition: background-color 400ms linear;
}

.transparentBG {
  background-color: transparent;
  transition: background-color 400ms linear;
}
