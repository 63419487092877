.about-wrapper {
  width: 100%;
  display: flex;
  height: fit-content;
  flex-wrap: wrap;
}

.leftColumn30 {
  width: 33.33%;
}

.rightColumn70 {
  height: 100%;
  width: 66.66%;
  padding: 10px;
  color: #4b4c4d;
}

@media (max-width: 700px) {
  .leftColumn30 {
    width: 100%;
  }
  .rightColumn70 {
    width: 100%;
    padding-left: 0px;
  }
}

.styledDividerLeft {
  display: block;
  height: 2px;
  border: 0;
  border-top: 2px solid #ccc;
  border-radius: 50px;
  margin: 20px 0px 20px 0px;
  width: 40px;
}

.about-short-desc {
  font-style: italic;
  color: #4b4c4d;
}

h2 {
  color: rgba(74, 74, 76, 1);
}

.about-long-desc {
  line-height: 24px;
}