#InfoSecImg {
  padding-bottom: 60px;
  background-color: white;
}

.gallery {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%;
  padding: 0 12px;
}

.gallery .pics {
  overflow: hidden;
  line-height: 0;
  max-width: 100%;
  max-height: 100%;
  cursor: pointer;
}

.imgGallery {
  transition: transform 2s, filter 1.5s ease-in-out;
  transform-origin: center center;
  filter: brightness(90%);
}

.pics-polaroid-wrapper:hover .imgGallery {
  transition: transform 5s, filter 1.5s ease-in-out;
  transform-origin: center center;
  filter: brightness(100%);
  transform: scale(1.05);
}

@media (max-width: 991px) {
  .gallery {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-width: 50%;
    -moz-column-width: 50%;
    column-width: 50%;
  }
  .imgGallery {
    transition: transform 0s, filter 0s ease-in-out;
    filter: brightness(100%);
    transform: scale(1);
  }
}

@media (max-width: 480px) {
  .gallery {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%;
  }
}

/*model*/

.model {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  transition: opacity 0.4s ease, visibility 0.4s ease,
    transform 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 999;
}

.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.model .imgGallery {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 0 20px;
  margin: 0 auto;
}

.closeIcon {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 10px;
  height: 10px;
  background-color: transparent;
  color: white;
  cursor: pointer;
}
.pics-polaroid-wrapper {
  display: inline-grid;
  width: 100%;
  margin-bottom: 15px;
}

.pics-polaroid-wrapper:hover {
  cursor: pointer;
}

.pics-polaroid-name {
  padding: 10px 6px 10px 6px;
  text-align: center;
  background-color: transparent;
  transition: transform 2s, filter 1.5s ease-in-out;
  transform-origin: center center;
  filter: brightness(75%);
}
/*
.pics-polaroid-wrapper:hover > .pics-polaroid-name {
  transition: transform 5s, filter 1.5s ease-in-out;
  transform-origin: center center;
  filter: brightness(100%);
}*/
