.detailed-portfolio-images-margin {
  margin-top: 110px;
}

.detailed-portfolio-images {
  text-align: center;
  max-height: 95vh;
  margin-bottom: 50px;
}
.img-size {
  width: 100%;
  object-fit: contain;
  max-height: inherit;
}
