.welcomeImage {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.text-center {
  text-align: center;
}

.hero-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.hero-container > h1 {
  color: #fff;
  font-size: 70px;
  margin-top: -100px;
  padding: 0px 70px 0px 70px;
}

.hero-container > p {
  margin-top: 8px;
  height: 42px;
  color: #fff;
  font-size: 42px;
  padding: 0px 70px 0px 70px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -150px;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 9vw;
    margin-top: -100px;
    text-align: center;
  }

  .hero-container > p {
    font-size: 30px;
    height: 30px;
    text-align: center;
  }
}
