.about-wrapper {
  width: 100%;
  display: flex;
  height: fit-content;
  flex-wrap: wrap;
}

.leftColumn50 {
  width: 50%;
  padding-right: 10px;
}

.rightColumn50 {
  height: 100%;
  width: 50%;
  padding: 10px;
  color: #4b4c4d;
  padding-left: 30px;
  text-align-last: center;
}

@media (max-width: 1100px) {
  .leftColumn50 {
    width: 100%;
  }
  .rightColumn50 {
    width: 100%;
    padding-left: 0px;
    padding-top: 30px;
  }
}

.styledDividerLeft {
  display: block;
  height: 2px;
  border: 0;
  border-top: 2px solid #ccc;
  border-radius: 50px;
  margin: 20px 0px 20px 0px;
  width: 40px;
}

.about-short-desc {
  font-style: italic;
  color: #4b4c4d;
}

h2 {
  color: rgba(74, 74, 76, 1);
}

.meImage {
  max-width: 100%;
  object-fit: cover;
}

.marginTop {
  margin-top: 50px;
}

.about-me-quote {
  font-size: 24px;
  padding: 30px 10px 50px 10px;
  font-style: italic;
  color: rgba(74, 74, 76, 1);
  line-height: 33px;

}

.about-me-text{
  line-height: 24px;
}
